
export default {
    data(){
        return {
            searchTimeoutHolder : null,
            searchFocus: false,
            searchKeywords: null,
            goingDown: false,
            showSwitchLang: false,
        }
    },
    computed: {
        wW(){
            return this.$store.state.scroll.wW;
        },
        filtersOpen:{
            get(){
                return this.$store.state.layout.filtersOpen
            },
            set(v){
                this.$store.commit('layout/setFiltersOpen',v);
            }
        },
        lang(){
            return this.$route.params.lang;
        },
        langs(){
            return this.$nuxt.$config.langs
        },
        isProductsPage(){
            return this.$route.fullPath.includes('products');
        },
        isProductsPageExact(){
            var pathElements = this.$route.path.split("/");
            var lastElement = pathElements.pop();
            if(lastElement == "") lastElement = pathElements.pop();
            return lastElement == 'products' || (this.$route.fullPath.includes('products') && !parseInt(lastElement));
        },
        isCheckout(){
            return this.$route.fullPath.includes("/checkout");
        },  
        logged(){
            return this.$store.state.user.jwt ? true : false;
        },
        cartsItemsCount(){
            return this.$store.state.cart.cart.quantity;
        },
        displayMode(){
            return this.$store.state.layout.displayMode;
        },
        menuOpen : {
            get(){
                return this.$store.state.layout.menuOpen;
            },
            set(v){
                this.$store.commit("layout/setMenuOpen",v)
            }
        },
        mobileSearch : {
            get(){
                return this.$store.state.layout.mobileSearch;
            },
            set(v){
                this.$store.commit("layout/setMobileSearch",v)
            }
        },
        searchOpen : {
            get(){
                return this.$store.state.layout.searchOpen;
            },
            set(v){
                this.$store.commit("layout/setSearchOpen",v)
            }
        },
        fullPath : {
            get(){
                return this.$route.fullPath;
            }
        },
        top : {
            get(){
                return this.$store.state.scroll.top;
            }
        },
        searchQuery(){
            return this.$route.query.s 
        },
        displayMode(){
            console.log("Header detect displaymode changed");
        }
    },
    created(){
        this.searchKeywords = this.searchQuery;
        this.searchFocus = this.isProductsPage;
    },
    watch : {
        fullPath(){
            this.menuOpen = false;
            this.searchOpen = false;
            this.searchFocus = this.isProductsPage;
        },
        searchQuery(){
            this.searchKeywords = this.searchQuery
        },
        top(t,f){
            this.goingDown = t > 30 && t > f;
        }
    },
    beforeDestroy() {
        if(this.searchTimeoutHolder) clearTimeout(this.searchTimeoutHolder);
    },
    methods: {
        openModalLogin(){
            if(!this.logged){
                this.$dialogUserLogin().then(res => {
                    this.$toastSuccess('userLogged')
                    res.close();
                    this.$router.push(this.$__url('/user/profile'))
                }).catch(err => {
                    return false;
                })
            }else{
                this.$router.push(this.$__url('/user/profile'))
            }
        },
        setSwitchLang(val){
            
            this.showSwitchLang= val;
        },
        selectLang(lang){
            this.showSwitchLang = false;
            const oldLang = this.lang;
            if(lang == oldLang) return
            this.$cookies.set('userLang', lang)
            var url = this.$route.path.replace('/'+oldLang+'/','/'+lang+'/');
            window.location.replace(url);
        },
        search(){
            if(this.isProductsPage || this.wW <= 600){
                const toReplace = JSON.parse(JSON.stringify(this.$route.query))
                if(toReplace.prodComp){
                    if (toReplace.prodComp != this.searchKeywords ){
                        delete toReplace.prodComp
                    }
                }
                toReplace.s =  this.searchKeywords
                this.$router.push({path: this.$__url('/products'), query: toReplace});
            }
        },
        searchDelay(){
            if(this.isProductsPage || this.wW <= 600){
                clearTimeout(this.searchTimeoutHolder);
                this.searchTimeoutHolder = setTimeout(this.search,1400);
            }

        }
    }
}
